import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { ImDownload2 } from "react-icons/im";
import toast from "react-hot-toast";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { getEnggAttendancePdf } from "../../../../ReduxSetup/Actions/AdminActions";

const DownloadEnggAttendanceForm = ({ engID, onClose }) => {

  const [attendanceData, setAttendanceData] = useState([]);

  // Function to calculate the last three months
  const getLastThreeMonths = () => {
    const currentDate = new Date();
    const result = [];

    for (let i = 0; i < 4; i++) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() - i;
      const calculatedDate = new Date(year, month, 1);

      const formatted = {
        year: calculatedDate.getFullYear(),
        month: calculatedDate.toLocaleString("default", { month: "long" }),
        monthNumeric: calculatedDate.getMonth() + 1,
      };
      result.push(formatted);
    }
    return result;
  };

  useEffect(() => {
    const lastThreeMonths = getLastThreeMonths();
    const mockFetch = lastThreeMonths.map((month) => ({
      month: month.month,
      monthNumeric: month.monthNumeric,
      year: month.year,
      data: `Attendance data for ${month.month} ${month.year}`,
    }));
    setAttendanceData(mockFetch);
  }, []);


  function convertTo12HourFormat(time) {
    if (time !== undefined) {
        const [hours, minutes] = time.split(":").map(Number);

        const period = hours >= 12 ? "PM" : "AM";
        const normalizedHour = hours % 12 || 12; // Handle 12 AM and 12 PM cases

        const formattedHour = normalizedHour < 10 ? `0${normalizedHour}` : normalizedHour;

        return `${formattedHour}:${minutes < 10 ? "0" + minutes : minutes} ${period}`;
    }
    return "--";
}



const calculateTotalHours = (checkIn, checkOut, checkintw, checkouttw) => {

  if (!checkIn || !checkOut || checkintw === "--" || checkouttw === "--") return "--";
  let checkInTime = new Date(`2000-01-01T${checkIn}`);
  let [hourss, minutess] = checkIn.split(":").map(Number);
  if (hourss > 24) {
      checkInTime = new Date(`2000-01-02T${checkintw}`);
  }
  let checkOutTime = new Date(`2000-01-01T${checkOut}`);
  let [hours2, minutes2] = checkOut.split(":").map(Number);
  if (hours2 > 24) {
      checkOutTime = new Date(`2000-01-02T${checkouttw}`);
  }


  const milliseconds = checkOutTime - checkInTime;
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};
  //------------------------------------------------------------------------------------------------------------------------------------------------
  const generatePdf = async (EngineerData) => {
    const content = `
    <div id="pdf-content" style="width: 100%;">
      <h1 style="text-align: center;">Engineer Attendance Report</h1>
      <h2 style="text-align: center;">ServiceEnggId: ${
        EngineerData[0]?.ServiceEnggId || "N/A"
      }</h2>
      <h2 style="text-align: center;">EnggName: ${
        EngineerData[0]?.engineerDetails[0]?.EnggName || "N/A"
      }</h2>
      <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
        <thead>
          <tr>
          <th style="padding: 8px; text-align: center; border: 1px solid #ddd; background-color: #f2f2f2; font-size: 38px;">Date</th>
            <th style="padding: 8px; text-align: center; border: 1px solid #ddd; background-color: #f2f2f2; font-size: 38px;">Check-In Time</th>
            <th style="padding: 8px; text-align: center; border: 1px solid #ddd; background-color: #f2f2f2; font-size: 38px;">Check-Out Time</th>
            <th style="padding: 8px; text-align: center; border: 1px solid #ddd; background-color: #f2f2f2; font-size: 38px;">Total_hours</th>
          </tr>
        </thead>
        <tbody>
          ${EngineerData.map(
            (engineer) => `
              <tr>
               <td style="padding: 8px; text-align: center; border: 1px solid #ddd; font-size: 32px;">${
                  engineer.Date || "N/A"
                }</td>
                <td style="padding: 8px; text-align: center; border: 1px solid #ddd; font-size: 32px;">${
                  convertTo12HourFormat(engineer.Check_In?.time) || "N/A"
                }</td>
                <td style="padding: 8px; text-align: center; border: 1px solid #ddd; font-size: 32px;">${
                  convertTo12HourFormat(engineer.Check_Out?.time) || "N/A"
                }</td>
                <td style="padding: 8px; text-align: center; border: 1px solid #ddd; font-size: 32px;">${
                  calculateTotalHours(engineer.Check_In?.time,engineer.Check_Out?.time) || "N/A"
                }</td>
               
               
              </tr>
            `
          ).join("")}
        </tbody>
      </table>
    </div>
  `;

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = content;
  document.body.appendChild(tempDiv);

  const pdfContent = tempDiv.querySelector("#pdf-content");
  const canvas = await html2canvas(pdfContent, {
    scale: 2, 
    useCORS: true, 
  });

  const imgData = canvas.toDataURL("image/jpeg", 0.5); 

  const pdf = new jsPDF("portrait", "mm", "a4");
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

  if (pdfHeight > pdf.internal.pageSize.getHeight()) {
    let currentHeight = 0;
    while (currentHeight < canvas.height) {
      const partialCanvas = canvas.getContext("2d").getImageData(
        0,
        currentHeight,
        canvas.width,
        Math.min(canvas.height - currentHeight, canvas.height / 2)
      );

      const partialImgData = canvas.toDataURL("image/jpeg", 0.5);
      pdf.addImage(partialImgData, "JPEG", 0, 0, pdfWidth, pdf.internal.pageSize.getHeight());
      currentHeight += canvas.height / 2;
      if (currentHeight < canvas.height) pdf.addPage();
    }
  } else {
    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
  }

  pdf.save(`${EngineerData[0]?.ServiceEnggId || "Engineer_Attendance_Report"}.pdf`);
  document.body.removeChild(tempDiv);
};





  const SetpassMonthAndYearData = async (month, year) => {
   
    const date = `${month}/${year}`;
    try {
      const data = await getEnggAttendancePdf(engID, date);

      // console.log("this is data console ------------- >>> >>>> >>>> >>> >> > > > > >> > ",data)

      if (data && data?.data?.EngineerData?.length > 0) {

        // convert24HourTo12Hour()


        generatePdf(data?.data?.EngineerData);
        toast.success(
          data?.data?.message || "Attendance PDF fetched successfully!"
        );
      } else {
        toast.error(data?.data?.message || "Failed to fetch attendance data.");
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      toast.error("An error occurred while fetching attendance data.");
    }
  };

  //-------------------------------------    logic to get images forme the S3 bucket through API   ---------------------------------------------

  return (
    <>
      <div className="bigg">
        <IoCloseOutline onClick={onClose} className="edit-engineer-modal" />

        <div className="ttt">
          <div className="qqq">
            <div className="attendancepopup-main-heading">
              <ImDownload2 />
              <p>Engginer Attendance</p>
            </div>
            {attendanceData &&
              attendanceData.map(
                (item) => (
                  (
                    <div
                      className="attendancepopup-heading"
                      onClick={() =>
                        SetpassMonthAndYearData(item.monthNumeric, item.year)
                      }
                    >
                      <p>{item.month}</p>
                      <p>{item.year}</p>
                    </div>
                  )
                )
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadEnggAttendanceForm;
